<template>
  <v-dialog v-model="visible" persistent max-width="700">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 pt-0 font-weight-bold">
        Mutasi Golongan / Kepangkatan Pegawai
      </v-card-text>
      <v-divider class="mb-5" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >NIP</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="detail.NIP"
              hide-details
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Nama</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              v-model="detail.nama"
              hide-details
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card outlined class="pa-5 mt-2">
          <p class="caption pt-0 font-weight-bold">Golongan dan Pangkat Baru</p>
          <v-row>
            <v-col cols="3" class="py-6">
              <v-subheader class="caption headline-color font-weight-regular"
                >Golongan/Pangkat</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-card-actions class="pa-0">
                <v-text-field
                  :value="detail.gol && detail.gol.gol"
                  disabled
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-text-field
                  class="ml-1"
                  :value="detail.pangkat && detail.pangkat.pangkat"
                  disabled
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-card-actions>
              <v-card-actions class="pa-0 mt-1">
                <v-autocomplete
                  :items="golonganList"
                  :loading="loading_golongan"
                  outlined
                  dense
                  v-model="form.gol"
                  item-text="gol"
                  item-value="gol"
                  return-object
                  :rules="[v => !!v || 'Golongan harus diisi']"
                ></v-autocomplete>
                <v-autocomplete
                  class="ml-1"
                  :items="pangkatList"
                  :loading="loading_pangkat"
                  outlined
                  dense
                  v-model="form.pangkat"
                  item-text="pangkat"
                  item-value="pangkat_id"
                  return-object
                  :rules="[v => !!v || 'Pangkat harus diisi']"
                ></v-autocomplete>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >TMT</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="detail.tgl_mulai_PNS"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="mt-1"
                v-model="form.tgl_mulai_PNS"
                type="date"
                outlined
                dense
                :rules="[v => !!v || 'TMT harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nomor SK</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="detail.no_sk_PNS"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="mt-1"
                v-model="form.no_sk_PNS"
                outlined
                dense
                :rules="[v => !!v || 'Nomor SK harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal SK</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="detail.tgl_sk_PNS"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="mt-1"
                v-model="form.tgl_sk_PNS"
                type="date"
                outlined
                dense
                :rules="[v => !!v || 'Tanggal SK harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="formLoading"
            type="submit"
            :disabled="!valid"
            form="form"
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import PublicService from "@/services/resources/public.service";

export default {
  data() {
    return {
      valid: false,
      visible: false,
      formLoading: false,
      loading_pangkat: false,
      loading_golongan: false,
      golonganList: [],
      pangkatList: [],
      detail: {
        NIP: null,
        nama: null,
        gol: null,
        pangkat: null,
        tgl_mulai_PNS: null,
        no_sk_PNS: null,
        tgl_sk_PNS: null
      },
      form: {
        gol: null,
        pangkat: null,
        tgl_mulai_PNS: null,
        no_sk_PNS: null,
        tgl_sk_PNS: null
      }
    };
  },
  methods: {
    change(val) {
      this.visible = val;
    },
    populate(item) {
      this.detail.NIP = item.NIP;
      this.detail.nama = item.nama;
      this.detail.gol = item.gol;
      this.detail.pangkat = item.pangkat;
      this.detail.tgl_mulai_PNS = item.tgl_mulai_PNS;
      this.detail.no_sk_PNS = item.no_sk_PNS;
      this.detail.tgl_sk_PNS = item.tgl_sk_PNS;
      this.visible = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        console.log(this.form);
      }
    },
    // Service
    async getLov(type) {
      try {
        this[`loading_${type}`] = true;
        await PublicService[`${type}GetList`]({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[`${type}List`] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[`loading_${type}`] = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getLov("golongan");
    this.getLov("pangkat");
  }
};
</script>
